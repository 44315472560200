.homepage

    +has(service-text-box)
        min-height: 200px

    +has(faq-box)
        min-height: 260px

    +has(service-card)
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px
        transition: all 0.5s cubic-bezier(.25,.8,.25,1)
        &:hover
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px

    +has(form-required)
        border-color: red !important
