@import  'variables';

// Mixins
@import  'mixins/bem';
@import  'mixins/screen';

// Styly projektu
@import "components/homepage";

.grecaptcha-badge {
    visibility: hidden !important
}


